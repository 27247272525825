import React, {Component} from "react";
import lockr from 'lockr';
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom';
import {ThemeProvider} from "./styles/styled-components";
import {theme} from "./styles/theme-goudenspelen";
import 'moment/locale/nl';
import CookieConsent, { Cookies } from "react-cookie-consent";
import { withTranslation, WithTranslation } from 'react-i18next';
import TagManager from 'react-gtm-module'

import config from './config';

lockr.prefix = config.LOCKR_PREFIX as string;

import {Header, Layout, Spinner, Footer} from "./components";
import {
	Home,
	NewTeam,
	EditTeam,
	Stats,
	Rules,
	Join,
	Rankings,
	MyTeams,
	UserProfile,
	Deadlines,
	Prizes,
	Points,
	LoginCallback
} from "./pages";
import {GlobalStyle, PageStyle} from "./styles/global";
import Race from "./pages/Race/Race";

function loggedIn(): boolean {
	return !!lockr.get('token');
}

const tagManagerArgs = {
	gtmId: 'GTM-KJGXL38',
	dataLayer: {}
  }
TagManager.initialize(tagManagerArgs)

class App extends React.PureComponent<WithTranslation> {

	componentDidMount () {
		// @ts-ignore
		window.$crisp = [];
		// @ts-ignore
		window.CRISP_WEBSITE_ID = "4e8a061e-e268-4fd9-b77c-be7881431995";

		(function() {
			var s = document.createElement("script");

			s.src = "https://client.crisp.chat/l.js";
			// @ts-ignore
			s.async = 1;
			document.getElementsByTagName("head")[0].appendChild(s);
		})();
		//payment ID and conversionValue in the url en via GTM uitlezen
		// // @ts-ignore
		// window.dataLayer.push({
		// 	event: 'team_paid',
		// 	authentication_type: 'mail',
		// 	user_id: res.user.id
		// });
	}

	render() {
		theme.primaryContrast = config.COMPETITION_CONFIG.gameColorContrast;
		theme.secondaryColor = config.COMPETITION_CONFIG.gameColor;

		// @ts-ignore
		const PrivateRoute = ({ component: Component, ...rest }) => (
			<Route {...rest} render={props => (
				loggedIn() ? (<Component {...props} {...{ t: this.props.t }} />) : (<Redirect to={{ pathname: '/home', state: { from: props.location } }} />)
			)} />
		);

		// @ts-ignore
		const PublicOnlyRoute = ({ component: Component, ...rest }) => (
			<Route {...rest} render={props => (
				!loggedIn() ? (<Component {...props} {...{ t: this.props.t }} />) : (<Redirect to={{ pathname: '/my-teams', state: { from: props.location } }} />)
			)} />
		);

		// @ts-ignore
		const UniversalRoute = ({ component: Component, ...rest }) => (
			<Route {...rest} render={props => ((<Component {...props} {...{ t: this.props.t }} />))} />
		);

		TagManager.dataLayer(tagManagerArgs);

		return (
			<ThemeProvider theme={theme}>
				<Router>
					<React.Fragment>
						<GlobalStyle/>
						{/* <CookieConsent
								location="bottom"
								buttonText="Ja, ik aanvaard de cookies"
								cookieName="cookies-accepted"
								style={{ background: "#FFD700", color: "#000", zIndex: 99999999999 }}
								buttonStyle={{ color: theme.primaryContrast, background: theme.secondaryColor, fontSize: "13px" }}
								enableDeclineButton={true}
								declineButtonClasses={'cookie-cancel-button'}
								declineButtonText={'Nee, ik surf verder met beperkte functionaliteit'}
								expires={365}
						>
								<p style={{ fontSize: "14px" }}>
								Deze website maakt gebruik van functionele en statistische cookies, gebaseerd op anonieme gegevens. We respecteren jouw privacy. Meer informatie vind je in de Cookieverklaring.
								</p>
						</CookieConsent> */}
						<div style={{minHeight: '100vh', margin: '0 auto -100px', backgroundColor: '#1D1D1D'}}>
							<Header {...{ t: this.props.t }} />
							<Spinner/>
							<div style={{backgroundColor: "#292929"}}>
								<PublicOnlyRoute exact path="/" component={Home}/>
								<UniversalRoute path="/home" component={Home}/>
								<PrivateRoute path="/new" component={NewTeam}/>
								<PrivateRoute path="/points/:id" component={Points}/>
								<PrivateRoute path="/public/:id" component={Points}/>
								<PrivateRoute path="/my-teams" component={MyTeams}/>
								<PrivateRoute path="/profile" component={UserProfile}/>
								<PrivateRoute path="/join/:code" component={Join}/>
								<UniversalRoute path="/rules" component={Rules}/>
								<UniversalRoute path="/deadlines" component={Deadlines}/>
								<UniversalRoute path="/race/:id" component={Race}/>
								<UniversalRoute path="/stats" component={Stats}/>
								<UniversalRoute path="/prizes" component={Prizes}/>
								<UniversalRoute path="/api/auth/callback/dpgmedia" component={LoginCallback}/>
								<UniversalRoute path="/rankings" component={Rankings}/>
							</div>
							<div style={{height: '100px'}}></div>
						</div>
						<Footer/>
					</React.Fragment>
				</Router>
			</ThemeProvider>
		);
	}
}

export default withTranslation('translation')(App);
