import styled from "../../styles/styled-components";
import { PlayerProps } from "./Player";
import { mediaQueries } from "../../styles/media-queries";

const PlayerStyle = styled.div`
	position: relative;
	cursor: pointer;
	width: 70px;
	height: 50px;
	text-align: ${(props: any) => !props.player ? 'center' : 'inherit'};

	@media ${mediaQueries.mobileM} {
		width: 75px;
		height: 65px;
	}

	@media ${mediaQueries.laptop} {
		width: 80px;
		height: 70px;
	}
`;

const PlayerBg = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 25%;
	opacity: ${(props: any) => props.inactive ? 0.6 : 1};
	background: url(${(props: any) => props.bg}) no-repeat center center/contain;
` as any;

const SoccerJersey = styled.div`
	position: absolute;
	top: 63%;
	left: 0;
	right: 0;
	bottom: 25%;
	background: url(${(props: any) => props.bg}) no-repeat center center/contain;
` as any;

const Badge = styled.div`
	position: absolute;
	top: 75%;
	bottom: 0;
	left: 0;
	right: 0;

	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${(props: any) => props.bgColor};

	@media ${mediaQueries.tablet} {
		padding: 0 5px;
		padding-right: ${(props: any) => props.paddingRight}px;
		overflow: hidden;
	}

	h4 {
		font-weight: 400;
		color: #000;
		line-height: 1.1;
		font-size: 9px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		margin: 0;

		@media ${mediaQueries.mobileM} {
			font-size: 10px;
		}

		@media ${mediaQueries.tablet} {
			font-family: inherit;
			font-size: 11px;
			font-weight: bold;
		}
	}
` as any;

const PointsBadge = styled.div`
	position: absolute;
	top: 100%;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
  	height: 20px;

	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${(props: any) => props.bgColor};

	@media ${mediaQueries.tablet} {
		padding: 0 5px;
		padding-right: ${(props: any) => props.paddingRight}px;
		overflow: hidden;
	}

	p {
		margin-top: 10px;
		font-size: 12px;
	}
` as any;

const Value = styled.div`
	top: 105%;
	bottom: 0;
	left: 0;
	position: absolute;
	right: 0;

	display: flex;
	justify-content: center;
	align-items: center;
	
	@media ${mediaQueries.tablet} {
		top: ${(props: any) => props.benchPlayer ? '110%' : '115%'};
	}

	h4 {
		font-family: "Roboto Condensed", sans-serif;
		font-weight: 400;
		color: ${(props: any) => props.color};
		background-color: #000;
		line-height: 1.1;
		font-size: 9px;
		white-space: nowrap;
		margin: 0;

		@media ${mediaQueries.mobileM} {
			font-size: 10px;
		}

		@media ${mediaQueries.tablet} {
			font-family: inherit;
			font-size: 11px;
			font-weight: bold;
		}
	}
` as any;

const Points = styled.span`
	font-family: "Helvetica Neue", sans-serif;
	font-weight: bold;
	color: ${(props: any) => props.color};
	background-color: ${(props: any) => props.bgColor};;
	align-self: flex-end;

	position: absolute;
	right: 0px;
	top: -45px;
	width: 20px;
	height: 20px;

	font-size: 10px;
	line-height: 1.1;

	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 100%;

	@media ${mediaQueries.mobileM} {
		top: -55px;
	}

	@media ${mediaQueries.tablet} {
		right: 0;
		top: 0;
		bottom: 0;
		width: 20px;
		height: auto;
		font-size: 11px;
		border-radius: 0;
		
	}
` as any;

const NoPlayer = styled.div`
	margin: 0 auto;
` as any;

const TopRightAction = styled.div`
	position: absolute;
	right: 10px;
	top: -5px;
	width: 20px;
	height: 20px;
	text-align: center;
	cursor: pointer;
	
	@media ${mediaQueries.mobileM} {
		right: 0px;
	}
` as any;

export {
	PlayerStyle,
	PlayerBg,
	SoccerJersey,
	Badge,
	Points,
	NoPlayer,
	TopRightAction,
	Value,
	PointsBadge
};