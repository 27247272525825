import styled, {keyframes} from "../../styles/styled-components";
import {mediaQueries, sizes} from "../../styles/media-queries";

import dropdownSvg from "../../assets/img/dropdown-action.svg";

const slideIn = keyframes`
	0% { opacity: 0; top: -5px; }
	30% { opacity: 1; top: -5px; }
	100% { opacity: 1; top:  0px; } 
`;

const HeaderBackground = styled.div`	
	display: flex;
	align-items: center;
	background: transparent linear-gradient(271deg, #F5D555 0%, #FDEFA7 48%, #F5D555 76%, #F4CF41 100%) 0% 0% no-repeat padding-box;
` as any;

const HeaderStyle = styled.header`
	display: flex;
	align-items: center;
	height: 50px
	
	.game-name {
		// color: ${props => props.theme.secondaryColor};
		text-transform: uppercase;
		display: none;
		margin: 10px;

		@media ${mediaQueries.tabletL} {
			display: block;
		}
	}

	@media ${mediaQueries.mobileM} {
		background-position: center bottom -60px;
	}

	@media ${mediaQueries.tablet} {
		background-position: center bottom -90px;
	}

	@media ${mediaQueries.desktop} {
		background-position: center bottom -170px;
	}

	@media ${mediaQueries.desktopL} {
		background-position: center bottom -220px;
	}
` as any;

const Logo = styled.div`
	/* display: flex;
	align-items: center; */
	margin: auto 40px;
	display: none;

	@media ${mediaQueries.tabletL} {
		margin-top: 50px;
		display: inherit;
	}

	a > img {
		@media ${mediaQueries.tabletL} {
			display: inherit;
			margin-top: -104px;
			height: 147px;
		}
	}

	.title {		
		@media ${mediaQueries.tabletL} {
			padding-left: 20px;
			padding-top: 10px;
		}

		h1 {
			color: white;
			line-height: 1;
			font-size: 18px;
			margin-bottom: 0;

			@media ${mediaQueries.tabletL} {
				margin-bottom: 5px;
				font-size: 24px;
			}
		}

		.sponsor {
			display: flex;
			align-items: center;

			h4 {
				color: white;
				font-size: 12px;
				margin-right: 10px;

				@media ${mediaQueries.tabletL} {
					font-size: 20px;
				}
			}			

			img {
				height: 30px;
				width: 50px;
			}
		}
	}
`;

const Nav = styled.nav`
	flex: 1;
	position: absolute;
	top: 0;
	left: -80vw;
	width: 80vw;
	height: 100vh;
	z-index: 9;

	display: flex;
	justify-content: center;

	@media ${mediaQueries.tabletL} {
		display: block;
		position: static;
		width: auto;
		height: auto;
		background-color: transparent;
		border-right: 0;
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		display: flex;
		flex-direction: column;

		@media (max-width: ${sizes.tabletL}px) {
			width: 100%;
		}

		.mobile-only {
			@media ${mediaQueries.tabletL} {
				display: none;
			}
		}

		.team-item {
			a {
				.team-name {
					margin-left: 10px;
				}
			}
		}

		.publisher {
			display: none;

			@media ${mediaQueries.desktop} {
				display: block;
				img {
					max-width: 200px;
					margin-top: 10px;
					margin-right: 10px;
					max-height: 30px;
				}
			}
			
		}

		li.has-children > a {
			&:after {
				content: '';
				width: 12px;
				height: 12px;
				background-image: url(${dropdownSvg});
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center center;
				margin-left: 5px;
				margin-top: 2px;
			}
		}
		
		li.has-children > ul {
				display: none;
		}
		
		li.active > a {
			height: 50px;
			border-bottom: 4px solid ${(props: any) => props.theme.secondaryColor};
		}
		
		li.team-item {
			@media ${mediaQueries.tabletL} {
				display: none;
			}
		}

		li.new-team > a {
			@media ${mediaQueries.tabletL} {
				color: ${(props: any) => props.theme.primaryContrast}
				background-color: ${props => props.theme.secondaryColor}
			}
		}

		li a {
			display: flex;
			height: 50px;
			color: #000;
			text-transform: uppercase;
			padding: 0 20px;
			transition: background-color 150ms ease-in;
			align-items: center;

			@media (max-width: 1124px) {
				padding: 0 10px;
			}

			@media (max-width: 984px) {
				padding: 0 5px;
			}

			@media (max-width: 913px) {
				padding: 0 0px;
			}

			@media (max-width: 845px) {
				padding: 0 20px;
			}

			.anticon {
				margin-right: 10px;
				
				@media ${mediaQueries.tabletL} {
					display: none;
				}
			}

			.teams-toggle {
				margin-left: 10px;
				padding: 10px;
			}

			@media ${mediaQueries.tabletL} {
				justify-content: center;
			}
			
			/* &:focus {
				text-decoration: none;
			} */

			@media ${mediaQueries.tabletL} {
				margin: 0px 7px;
			}
			
			&:hover {
				background-color: ${props => props.theme.primaryColor}
			}
		}
	}

	> ul {
		
		@media ${mediaQueries.tabletL} {
			flex-direction: row;
			background-position: center bottom -90px;
			justify-content: flex-end;
			flex-wrap: wrap;
		}

		> li {
			position: relative;
			/* top: -5px; */

			&.open {
				> ul {
					display: inherit;
					> li {
						animation: 250ms ${slideIn} ease-in;
						animation-fill-mode: forwards;

						&:nth-child(2) {
							animation-delay: 50ms;
						}
						
						&:nth-child(3) {
							animation-delay: 100ms;
						}

						&:nth-child(4) {
							animation-delay: 150ms;
						}
					}
				}
			}

			> a {
				
			}

			> ul {
				position: absolute;

				@media ${mediaQueries.tabletL} {
					right: 0;
				}

				> li {
					opacity: 0;
					position: relative;					
				}
			}
		}
	}
`;

const Hamburger = styled.button`
	background: none;
	appearance: none;
	border: 0;
	font-size: 16px;
	width: auto;
	color: white;
	text-align: left;
	margin-left: 5px;
	color: #000;

	.anticon {
		margin-right: 10px;
	}

	@media ${mediaQueries.tabletL} {
		display: none;
	}
`;

const TopHeaderStyle = styled.div`
	display: none;

	@media ${mediaQueries.tabletL} {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	ul {
		color: #838383;
		li {
			display: inline-block;
			margin: 10px 10px 0px 10px;

			a {
				color: #FFF;
				text-decoration: underline;
			}
		}
	}

	ul.games {
		margin: 0;
		padding: 0;

		li {
			a {
				text-decoration: none;
				border-bottom: 0;
			}

			a.active {
				border-bottom: 2px solid #FFD700;
			}
		}
	}
`;

const MobileTabInfoStyle = styled.div`
	position: absolute;
	right: 0px;
	font-size: 11px;
	top: 10px;
	right: 10px;
	color: #000;

	span {
		display: block;

		span {
			display: inline-block;
		}
	}
`;

export {
	HeaderStyle,
	Logo,
	Nav,
	Hamburger,
	HeaderBackground,
	TopHeaderStyle,
	MobileTabInfoStyle
};