import React, {Component} from "react";
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {Route} from 'react-router-dom';
import {TeamContainer} from '../../pages';

import * as userActions from '../../actions/user'
import {StoreState, ApplicationState, UserState, MatchesState} from '../../types';
import {MyTeamsStyles} from './MyTeamsStyles';

import {
	Layout,
	Sidebar,
	Row,
	Col,
	Payments,
	Dashboard
} from '../../components';

export interface Props {
	application: ApplicationState;
	user: UserState;
	matches: MatchesState;
	match: any;
}

export interface State {
	
}

class Stats extends Component<Props, State> {

	constructor(props: Props) {
		super(props);

		this.state = {
		
		};
	};

	render() {
		const { match } = this.props;
		
		return (
			<Layout>
			<MyTeamsStyles>
				<Row>
					<Col md={5} lg={4} className={'sidebar'}>
						<Sidebar {...this.props}/>
					</Col>
					<Col md={19} lg={20} sm={24} xs={24} className={'content'}>
						<Route exact path={`${match.path}`} render={(routeProps) => <Dashboard {...this.props} {...routeProps} />} />
						<Route path={`${match.path}/team/:id`} render={(routeProps) => <TeamContainer {...this.props} {...routeProps}  />}/>
						<Route path={`${match.path}/payments`} component={Payments} />
					</Col>
				</Row>
			</MyTeamsStyles></Layout>
		);
	}
}

export function mapDispatchToProps(dispatch: Dispatch<userActions.UserAction>) {
	return {}
}

export function mapStateToProps({application, user, matches}: StoreState.All) {
	return {
		application,
		user,
		matches
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Stats);