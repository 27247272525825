import React, { Component } from 'react';
import { TeamStyle, Sponsor } from './TeamStyle';
import Player, { PlayerType } from '../Player/Player';
import {Club} from './../../types';

export interface TeamState {
}

export interface TeamProps {
	bg: string;
	bgSponsor?: string;
	widthRatio: number;
	heightRatio: number;
	playerType: PlayerType;
	selection: any;
	playerBadgeColor: string;
	playerBadgeBgColor: string;
	playerPointsColor: string;
	playerPointsBgColor: string;
	onRemove?: any;
	onSwap?: any;
	isSwapAble?: any;
	onCaptainSelect?: any;
	modalEnabled? : boolean;
	onPlaceholderClick?: any;
	swapPlayerId?: number | null;
	swappedFrom?: string | null;
	assetsCdn: string;
	actionLessPlayerIds?: any[];
	showPlayerValue?: boolean;
	showPlayerStatsPoints?: boolean;
	clubs: Club[];
	showPlayerValueInsteadOfPoints?: boolean;
	captainId?: number;
	centerAligned?: boolean | undefined;
}

class Team extends Component<TeamProps, TeamState> {
	render() {
		const {
			bg,
			bgSponsor,
			widthRatio,
			heightRatio,
			selection,
			playerType,
			playerBadgeColor,
			playerBadgeBgColor,
			playerPointsColor,
			playerPointsBgColor,
			onRemove,
			onSwap,
			isSwapAble,
			onPlaceholderClick,
			swapPlayerId,
			swappedFrom,
			assetsCdn,
			actionLessPlayerIds,
			showPlayerValue,
			showPlayerStatsPoints,
			showPlayerValueInsteadOfPoints,
			clubs,
			onCaptainSelect,
			modalEnabled,
			captainId,
			centerAligned
		} = this.props;

		return (
			<TeamStyle bg={bg} widthRatio={widthRatio} heightRatio={heightRatio} centerAligned={centerAligned}>
				{bgSponsor && <Sponsor bg={bgSponsor} widthRatio={widthRatio} heightRatio={heightRatio} />}
				{selection.map((position: any, positionIndex: number) => {
					const sportSpecificProps: { shirtCycling?: string, club?: Club } = {};

					return (
						<div className={`position`} key={`positionIndex-${positionIndex}`}>
							{
								position.map((player: any, playerIndex: number) => {

									if ((playerType === PlayerType.Cycling || playerType === PlayerType.Cyclocross) && player) {
										const club = clubs.find((item: Club, index: number) => item.id === player.clubId);

										sportSpecificProps.shirtCycling = `${assetsCdn}/jerseys/cycling/${player.clubId}.png`;
										sportSpecificProps.club = club;
									}

									return <Player
										key={`playerIndex-${playerIndex}`}
										pointsColor={playerPointsColor}
										pointsBgColor={playerPointsBgColor}
										badgeColor={playerBadgeColor}
										badgeBgColor={playerBadgeBgColor}
										modalEnabled={modalEnabled}
										captainId={captainId}
										player={player}
										showPlayerValue={showPlayerValue}
										showPlayerStatsPoints={showPlayerStatsPoints}
										type={playerType}
										onRemove={onRemove}
										showPlayerValueInsteadOfPoints={showPlayerValueInsteadOfPoints}
										onSwap={onSwap}
										isSwapAble={isSwapAble}
										onCaptainSelect={onCaptainSelect}
										onPlaceholderClick={onPlaceholderClick}
										swapPlayerId={swapPlayerId}
										actionLessPlayerIds={actionLessPlayerIds}
										swappedFrom={swappedFrom}
										{...sportSpecificProps}
									/>
								})
							}
						</div>
					);
				})}
			</TeamStyle>
		);
	}
}

export default Team;